<template>
  <div>
    <el-page-header @back="back" content="领域详情"> </el-page-header>
    <div class="container">
      <el-descriptions title="领域信息" border direction="vertical">
        <el-descriptions-item label="图标">
          <el-image
            :src="circleData.icon"
            style="width: 30px"
            :preview-src-list="[circleData.icon]"
          />
        </el-descriptions-item>
        <el-descriptions-item label="背景图">
          <el-image
              :src="circleData.bgUrl"
              style="width: 30px"
              :preview-src-list="[circleData.bgUrl]"
          />
        </el-descriptions-item>
        <el-descriptions-item label="二维码">
          <el-image
              :src="circleData.qrCode"
              style="width: 30px"
              :preview-src-list="[circleData.qrCode]"
          />
        </el-descriptions-item>
        <el-descriptions-item label="名称">{{
          circleData.name
        }}</el-descriptions-item>
        <el-descriptions-item label="简介">{{
          circleData.descriptions
        }}</el-descriptions-item>
      </el-descriptions>
      <el-tabs
        v-model="activeName"
        type="card"
        @tab-click="handleClick"
        style="width: 100%; margin: 20px 0"
      >
        <el-tab-pane label="成员列表" name="member">
          <el-table :data="memberList" border style="margin-top: 10px">
            <el-table-column prop="id" label="用户ID"></el-table-column>
            <el-table-column prop="userName" label="用户昵称"></el-table-column>
            <el-table-column prop="userAvatar" label="用户头像" align="center">
              <template slot-scope="scope">
                <el-popover placement="top" width="200" trigger="hover">
                  <el-avatar :size="100" :src="scope.row.userAvatar" />
                  <el-avatar slot="reference" :src="scope.row.userAvatar" />
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="statusDes" label="状态"></el-table-column>
            <el-table-column
              prop="roleTypeDes"
              label="用户角色"
            ></el-table-column>
            <el-table-column prop="releaseTime" label="加入时间" width="180">
              <template slot-scope="scope">
                <span>{{ formatTime(scope.row.createTime) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="circleData.manager"
              label="操作"
              align="center"
            >
              <template slot-scope="scope">
                <div style="display: flex; justify-content: space-evenly">
                  <el-button
                    v-if="scope.row.status != 'APPROVED'"
                    size="mini"
                    type="text"
                    @click="auditMember(scope.row)"
                    >审核</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    @click="kickoutMember(scope.row)"
                    >踢出</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="total, prev, pager, next"
            :total="memberTotalRows"
            :current-page="memberPage"
            :page-size="20"
            style="margin-top: 20px"
            @current-change="handleChangePage"
          >
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="商品列表" name="spu">
          <div style="display: flex" v-if="circleData.manager">
            <el-button type="primary" size="small" @click="showAddSpuDialog"
              >添加商品</el-button
            >
          </div>
          <el-table :data="circleSpuList" border style="margin-top: 10px">
            <el-table-column prop="id" label="商品ID"></el-table-column>
            <el-table-column prop="name" label="商品名称"></el-table-column>
            <el-table-column prop="logoUrl" label="商品封面图" align="center">
              <template slot-scope="scope">
                <el-image
                  :src="scope.row.logoUrl"
                  fit="contain"
                  style="width: 100px"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="countBuy" label="购买数量"></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="spuDetail(scope.row)"
                  >详情</el-button
                >
                <el-popconfirm
                  v-if="circleData.manager"
                  title="确认解绑课程吗？"
                  @confirm="unbindCourse(scope.row)"
                >
                  <el-button
                    slot="reference"
                    type="text"
                    style="margin-left: 10px"
                    >解绑</el-button
                  >
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="total, prev, pager, next"
            :total="circleSpuTotalRows"
            :current-page="circleSpuPage"
            :page-size="20"
            style="margin-top: 20px"
            @current-change="handleChangePage"
          >
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="帖子列表" name="posts">
          <div style="display: flex">
            <el-button type="primary" size="small" @click="addPosts(null)"
              >发帖</el-button
            >
          </div>
          <el-table :data="postsList" border style="margin-top: 10px">
            <el-table-column prop="id" label="帖子ID" width="150" fixed>
            </el-table-column>
            <el-table-column prop="userId" label="发帖人ID" width="150">
            </el-table-column>
            <el-table-column
              prop="userAvatar"
              label="发帖人头像"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                <el-popover placement="top" width="200" trigger="hover">
                  <el-avatar :size="200" :src="scope.row.userAvatar" />
                  <el-avatar slot="reference" :src="scope.row.userAvatar" />
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="发帖人姓名" width="100">
            </el-table-column>
            <el-table-column prop="company" label="发帖人公司" width="100">
            </el-table-column>
            <el-table-column prop="jobTitle" label="发帖人职位" width="100">
            </el-table-column>
            <el-table-column prop="title" label="标题" width="180">
            </el-table-column>
            <el-table-column prop="briefText" label="内容" width="200">
              <template slot-scope="scope">
                <span class="multi-line" v-html="scope.row.briefText"></span>
              </template>
            </el-table-column>
            <el-table-column prop="browseCount" label="浏览数">
            </el-table-column>
            <el-table-column prop="commentCount" label="评论数">
            </el-table-column>
            <el-table-column prop="hidden" label="是否隐藏">
              <template slot-scope="scope">
                {{ scope.row.hidden ? "已隐藏" : "已显示" }}
              </template>
            </el-table-column>
            <el-table-column prop="auditStatusDes" label="审核状态">
            </el-table-column>
            <el-table-column prop="releaseTime" label="发布时间" width="180">
              <template slot-scope="scope">
                <span>{{ formatTime(scope.row.releaseTime) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              width="180"
              fixed="right"
            >
              <template slot-scope="scope">
                <div style="display: flex; justify-content: space-evenly">
                  <el-button
                    size="mini"
                    type="text"
                    @click="addPosts(scope.row, true)"
                    >详情</el-button
                  >
                  <template>
                    <el-button
                      size="mini"
                      type="text"
                      @click="addPosts(scope.row, false)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="text"
                      @click="hidePosts(scope.row)"
                      >{{ scope.row.hidden ? "显示" : "隐藏" }}</el-button
                    >
                    <el-button
                        size="mini"
                        type="text"
                        v-if="scope.row.auditStatus !=='APPROVED'"
                        @click="auditPosts(scope.row)"
                    >审核通过</el-button
                    >
                  </template>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="total, prev, pager, next"
            :total="postsTotalRows"
            :current-page="postsPage"
            :page-size="20"
            style="margin-top: 20px"
            @current-change="handleChangePage"
          >
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="问答列表" name="qa">
          <el-table :data="qaList" border>
            <el-table-column prop="id" label="帖子ID" width="150" fixed>
            </el-table-column>
            <el-table-column prop="userId" label="提问人ID" width="150">
            </el-table-column>
            <el-table-column
              prop="userAvatar"
              label="提问人头像"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                <el-popover placement="top" width="200" trigger="hover">
                  <el-avatar :size="200" :src="scope.row.userAvatar" />
                  <el-avatar slot="reference" :src="scope.row.userAvatar" />
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="提问人姓名">
            </el-table-column>
            <el-table-column prop="title" label="标题" width="180">
            </el-table-column>
            <el-table-column prop="briefText" label="内容" width="200">
              <template slot-scope="scope">
                <span v-html="scope.row.briefText"></span>
              </template>
            </el-table-column>
            <el-table-column prop="browseCount" label="浏览数">
            </el-table-column>
            <el-table-column prop="commentCount" label="评论数">
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="total, prev, pager, next"
            :total="qaTotalRows"
            :current-page="qaPage"
            :page-size="20"
            style="margin-top: 20px"
            @current-change="handleChangePage"
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="评论列表" name="comment">
          <el-table :data="commentList" border style="margin-top: 20px">
            <el-table-column prop="id" label="评论ID"> </el-table-column>
            <el-table-column prop="userId" label="评论人ID" width="150">
            </el-table-column>
            <el-table-column
              prop="userAvatar"
              label="头像"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                <el-popover placement="top" width="200" trigger="hover">
                  <el-avatar :size="200" :src="scope.row.userAvatar" />
                  <el-avatar slot="reference" :src="scope.row.userAvatar" />
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="姓名"> </el-table-column>
            <el-table-column prop="company" label="公司"> </el-table-column>
            <el-table-column prop="jobTitle" label="职位"> </el-table-column>
            <el-table-column prop="content" label="评论内容" width="200">
              <template slot-scope="scope">
                <el-popover
                  placement="top"
                  width="280"
                  trigger="hover"
                  :content="scope.row.content"
                >
                  <span slot="reference" class="multi-line">{{
                    scope.row.content
                  }}</span>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="likeCount" label="点赞数"> </el-table-column>
            <el-table-column prop="replyCount" label="回复数">
            </el-table-column>
            <el-table-column prop="auditStatusDes" label="审核状态">
            </el-table-column>
            <el-table-column prop="releaseTime" label="发布时间" width="180">
              <template slot-scope="scope">
                <span>{{ formatTime(scope.row.createTime) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="circleData.manager"
              label="操作"
              align="center"
              width="120"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-popconfirm
                  title="是否删除该评论"
                  @confirm="deleteComment(scope.row)"
                >
                  <el-button slot="reference" size="mini" type="text"
                    >删除</el-button
                  >
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="total, prev, pager, next"
            :total="commentTotalRows"
            :current-page="commentPage"
            :page-size="20"
            style="margin-top: 20px"
            @current-change="handleChangePage"
          >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      title="绑定课程商品"
      :visible="dialogVisible"
      @close="closeSpuDialog"
    >
      <el-table
        ref="multipleTable"
        :data="spuList"
        border
        style="margin-top: 10px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" label="商品ID"></el-table-column>
        <el-table-column prop="name" label="商品名称"></el-table-column>
        <el-table-column prop="logoUrl" label="商品封面图" align="center">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.logoUrl"
              fit="contain"
              style="width: 100px"
            ></el-image>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="spuTotalRows"
        :current-page="spuPage"
        :page-size="20"
        style="margin-top: 20px"
        @current-change="handleSpuChangePage"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeSpuDialog">取 消</el-button>
        <el-button type="primary" @click="bindSpu">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FieldApi from "../../request/FieldApi";
import PostsApi from "../../request/PostsApi";
import CommentApi from "../../request/CommentApi";
import SpuApi from "../../request/SpuApi";
import dayjs from "dayjs";
export default {
  data() {
    return {
      circleId: null,
      circleData: {},
      activeName: "member",
      memberList: [],
      memberPage: 1,
      memberTotalRows: 0,
      postsList: [],
      postsPage: 1,
      postsTotalRows: 0,
      circleSpuList: [],
      circleSpuPage: 1,
      circleSpuTotalRows: 0,
      spuList: [],
      spuPage: 1,
      spuTotalRows: 0,
      dialogVisible: false,
      selectedSpuList: [],
      commentList: [],
      commentPage: 1,
      commentTotalRows: 0,
      qaList: [],
      qaPage: 1,
      qaTotalRows: 0,
    };
  },
  mounted() {
    this.circleId = this.$route.query.id;
    this.loadData();
    this.getMemberList();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    loadData() {
      FieldApi.detail({
        id: this.circleId,
      }).then((res) => {
        if (res.success) {
          this.circleData = res.data;
        }
      });
    },
    handleClick(tab) {
      this.activeName = tab.name;
      if (tab.name === "member") {
        this.getMemberList();
      } else if (tab.name === "posts") {
        this.getPostsList();
      } else if (tab.name === "comment") {
        this.getCommentList();
      } else if (tab.name === "qa") {
        this.getQAList();
      } else {
        this.getSpuList(true);
      }
    },
    handleChangePage(page) {
      if (this.activeName === "member") {
        this.memberPage = page;
        this.getMemberList();
      } else if (this.activeName === "posts") {
        this.postsPage = page;
        this.getPostsList();
      } else if (this.activeName === "comment") {
        this.commentPage = page;
        this.getCommentList();
      } else if (this.activeName === "qa") {
        this.qaPage = page;
        this.getQAList();
      } else {
        this.circleSpuPage = page;
        this.getSpuList(true);
      }
    },
    getMemberList() {
      FieldApi.memberList({
        circleId: this.circleId,
        pageNo: this.memberPage,
        pageSize: 20,
      }).then((res) => {
        if (res.success) {
          this.memberList = res.data;
          this.memberTotalRows = res.totalRows;
        }
      });
    },
    getPostsList() {
      PostsApi.list({
        pageNo: this.postsPage,
        pageSize: 20,
        isBackend: true,
        circleId: this.circleId,
      }).then((res) => {
        if (res.success) {
          this.postsList = res.data;
          this.postsTotalRows = res.totalRows;
        }
      });
    },
    getQAList() {
      PostsApi.list({
        pageNo: this.qaPage,
        pageSize: 20,
        circleId: this.circleId,
        qaType: true,
      }).then((res) => {
        if (res.success) {
          this.qaList = res.data;
          this.qaTotalRows = res.totalRows;
        }
      });
    },
    getCommentList() {
      CommentApi.list({
        pageNo: this.commentPage,
        pageSize: 20,
        commentSort: "NEWEST",
        circleId: this.circleId,
      }).then((res) => {
        if (res.success) {
          this.commentList = res.data;
          this.commentTotalRows = res.totalRows;
        }
      });
    },
    getSpuList(isCircle = false) {
      const param = {
        pageNo: this.spuPage,
        pageSize: 20,
        userId: this.circleData.userId,
        spuType: "COURSE_GOODS",
      };
      if (isCircle) {
        param.circleId = this.circleId;
      }
      SpuApi.list(param).then((res) => {
        if (res.success) {
          if (isCircle) {
            this.circleSpuList = res.data;
            this.circleSpuTotalRows = res.totalRows;
          } else {
            this.spuList = res.data;
            this.spuTotalRows = res.totalRows;
          }
        }
      });
    },
    formatTime(val) {
      return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
    },
    auditMember(val) {
      let that = this;
      this.$confirm("请审核用户的加入请求", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "通过",
        cancelButtonText: "拒绝",
        type: "warning",
      })
        .then(() => {
          FieldApi.auditMember({
            circleId: that.circleId,
            status: "APPROVED",
            userId: val.id,
          }).then((res) => {
            if (res.success) {
              that.$message.success("审核通过");
              val.status = "APPROVED";
              val.statusDes = "审核通过";
            }
          });
        })
        .catch((action) => {
          if (action === "cancel") {
            FieldApi.auditMember({
              circleId: that.circleId,
              status: "REJECT",
              userId: val.id,
            }).then((res) => {
              if (res.success) {
                that.$message.info("已拒绝");
                val.status = "REJECT";
                val.statusDes = "审核拒绝";
              }
            });
          }
        });
    },
    kickoutMember(val) {
      FieldApi.kickout({
        circleId: this.circleId,
        userId: val.id,
      }).then((res) => {
        if (res.success) {
          this.$message.info("已踢出成员");
          this.getMemberList();
        }
      });
    },
    addPosts(val, isDetail) {
      if (val) {
        this.$router.push({
          path: "/field/posts/add",
          query: { id: val.id, circleId: this.circleId, isDetail: isDetail },
        });
      } else {
        this.$router.push({
          path: "/field/posts/add",
          query: { circleId: this.circleId, isDetail: false },
        });
      }
    },
    hidePosts(val) {
      PostsApi.hidden({
        hidden: !val.hidden,
        postId: val.id,
      }).then((res) => {
        if (res.success) {
          this.$message("修改成功");
          val.hidden = !val.hidden;
        }
      });
    },
    auditPosts(val) {
      PostsApi.update({
        ...val,
        auditStatus: 'APPROVED'
      }).then((res) => {
        if (res.success) {
          this.$message("审核成功");
          this.loadData();
        }
      });
    },
    spuDetail(val) {
      this.$router.push({
        path: "/spu/detail",
        query: {
          id: val.id,
        },
      });
    },
    showAddSpuDialog() {
      this.dialogVisible = true;
      this.getSpuList();
    },
    closeSpuDialog() {
      this.dialogVisible = false;
      this.$refs.multipleTable.clearSelection();
      this.selectedSpuList = [];
    },
    handleSpuChangePage(page) {
      this.spuPage = page;
      this.getSpuList();
    },
    handleSelectionChange(val) {
      this.selectedSpuList = val;
    },
    bindSpu() {
      if (!this.selectedSpuList.length) {
        this.$message("请选择要绑定的商品");
        return;
      }
      var spuIdList = [];
      this.selectedSpuList.forEach((element) => {
        spuIdList.push(element.id);
      });
      FieldApi.bindCourse({
        circleId: this.circleId,
        spuList: spuIdList,
      }).then((res) => {
        if (res.success) {
          this.$message.success("添加成功");
          this.closeSpuDialog();
          this.getSpuList(true);
        }
      });
    },
    unbindCourse(val) {
      FieldApi.unbindCourse({
        circleId: this.circleId,
        spuList: [val.id],
      }).then((res) => {
        if (res.success) {
          this.$message.success("解绑成功");
          this.getSpuList(true);
        }
      });
    },
    deleteComment(val) {
      CommentApi.delete({
        id: val.id,
      }).then((res) => {
        if (res.success) {
          this.$message.success("删除成功");
          let index = this.commentList.indexOf(val);
          this.commentList.splice(index, 1);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.multi-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.container {
  position: fixed;
  overflow: auto;
  top: 140px;
  width: calc(100vw - 200px - 40px);
  height: calc(100vh - 140px);
}
</style>
