const baseUrl = "/zp-community/v1/comment/"
import http from './http';
class CommentApi {
  static list(param) {
    return http.post(baseUrl + "tourist/comment-list", param)
  }
  static delete(param) {
    return http.post(baseUrl + "delete", param)
  }
}
export default CommentApi;
