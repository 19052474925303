import http from './http';
const baseUrl = "/zp-community/v1/circle/"
class FieldApi {
  static list(param) {
    return http.post(baseUrl + "my-circle-list", param)
  }
  static cancelHot(param) {
    return http.post(baseUrl + "cancel-hot", param)
  }
  static setHot(param) {
    return http.post(baseUrl + "set-hot", param)
  }
  static detail(param) {
    return http.post(baseUrl + "tourist/detail", param)
  }
  static insert(param) {
    return http.post(baseUrl + "insert", param)
  }
  static update(param) {
    return http.post(baseUrl + "update", param)
  }
  static openMemberAudit(param) {
    return http.post(baseUrl + "open-member-audit", param)
  }
  static openPost(param) {
    return http.post(baseUrl + "open-post", param)
  }
  static openPayment(param) {
    return http.post(baseUrl + "open-payment", param)
  }
  static memberList(param) {
    return http.post(baseUrl + "tourist/circle-member-list", param)
  }
  static kickout(param) {
    return http.post(baseUrl + "kick-out", param)
  }
  static auditMember(param) {
    return http.post(baseUrl + "audit-member", param)
  }
  static bindCourse(param) {
    return http.post(baseUrl + "bind-course", param)
  }
  static unbindCourse(param) {
    return http.post(baseUrl + "unbind-course", param)
  }

}
export default FieldApi;
