const baseUrl = "/zp-community/v1/post/"
const backEndBaseUrl = "/zp-community/v1/backend/post/"
import http from './http';
class PostsApi {
  static list(param) {
    return http.post(baseUrl + "tourist/circle-post-list", param)
  }
  static detail(param) {
    return http.post(baseUrl + "tourist/detail", param)
  }
  static insert(param) {
    return http.post(baseUrl + "insert", param)
  }
  static update(param) {
    return http.post(backEndBaseUrl + "update", param)
  }
  static hidden(param) {
    return http.post(backEndBaseUrl + "hidden", param)
  }
}
export default PostsApi;
